<template>
  <div>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm6 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add Food & Beverages</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pb-4 px-8>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm3 pt-4 px-2>
            <span class="title2">Is GST present?</span>

            <v-radio-group
              dense
              v-model="gstAvailable"
              row
              class="mt-0 pt-0"
              hide-details="auto"
            >
              <v-radio color="orange" label="Yes" :value="true"></v-radio>
              <v-radio color="orange" label="No" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 sm5 pt-4 px-2 text-left v-if="gstAvailable === true">
            <span class="title2">GST Number</span>
            <v-text-field
              dense
              v-model="gstNumber"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <!-- <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Breakfast Items</span>
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field
                  dense
                  v-model="newItemName"
                  outlined
                  type="text"
                  hide-details="auto"
                  placeholder="Item Name"
                />
              </v-flex>
              <v-flex xs4 pl-1>
                <v-text-field
                  dense
                  v-model="newRate"
                  outlined
                  type="number"
                  hide-details="auto"
                  placeholder="Rate"
                />
              </v-flex>
              <v-flex xs2 align-self-center text-center>
                <v-icon
                  @click="addBreakfastItem"
                  :disabled="!newItemName || !newRate"
                  >mdi-plus</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="breakfastArray.length > 0">
              <v-flex
                xs12
                pt-4
                v-for="(item, index) in breakfastArray"
                :key="index"
              >
                <v-layout wrap>
                  <v-flex xs6>
                    <v-text-field
                      dense
                      hide-details
                      v-model="item.itemName"
                      outlined
                      type="text"
                      disabled
                    />
                  </v-flex>
                  <v-flex xs4 pl-1>
                    <v-text-field
                      dense
                      hide-details
                      v-model="item.rate"
                      outlined
                      type="number"
                      disabled
                    />
                  </v-flex>
                  <v-flex xs2 align-self-center text-center>
                    <v-icon @click="deleteBreakfastItem(index)"
                      >mdi-delete</v-icon
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex> -->
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Cake</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isCake"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="cakeRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Fruit Basket</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isFruitBasket"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="fruitBasketRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">BBQ Grill</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isBBQ"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="bbqRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Cook & Butler Service</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isCook"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="cookRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Honeymoon Inclusion</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isHoneymoon"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="honeymoonRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12>
        <v-layout wrap justify-start px-2>
          <v-flex xs12 pt-4 >
            <span class="title2">Per Head Rate:</span>
          </v-flex>
          <v-flex xs12 sm6 md3 pr-0 pr-md-2 pt-4>
            <v-layout wrap justify-start>
              <v-flex xs6 align-self-center>
                <span class="title2">Breakfast</span>
              </v-flex>
              <v-flex xs4 align-self-center>
                <v-text-field
                  dense
                  v-model="breakfastRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="breakfastMenu"
                  outlined
                  type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md2 pr-0 pr-md-2 pt-4>
            <v-layout wrap justify-start>
              <v-flex xs4 align-self-center>
                <span class="title2">Lunch</span>
              </v-flex>
              <v-flex xs6 align-self-center>
                <v-text-field
                  dense
                  v-model="lunchRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="lunchMenu"
                  outlined
                  type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md4 pr-0 pr-md-2 pt-4>
            <v-layout wrap justify-start>
              <v-flex xs6 align-self-center>
                <span class="title2">Tea/Coffee-Snacks</span>
              </v-flex>
              <v-flex xs4 align-self-center>
                <v-text-field
                  dense
                  v-model="snacksRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="snacksMenu"
                  outlined
                   type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md3  pt-4>
            <v-layout wrap justify-start>
              <v-flex xs4 align-self-center>
                <span class="title2">Dinner</span>
              </v-flex>
              <v-flex xs6 align-self-center>
                <v-text-field
                  dense
                  v-model="dinnerRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="dinnerMenu"
                  outlined
                  type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex> -->
      <v-flex xs12>
    <v-layout wrap justify-start px-2>
      <v-flex xs12 pt-4>
        <span class="title2">Per Head Rate:</span>
      </v-flex>

      <!-- Breakfast Section -->
      <v-flex xs12 sm6 md3 pr-0 pr-md-2 pt-4>
        <v-layout wrap justify-start>
          <v-flex xs6 align-self-center>
            <span class="title2">Breakfast</span>
          </v-flex>
          <v-flex xs4 align-self-center>
            <v-text-field
              dense
              v-model="breakfastRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            />
          </v-flex>

          <!-- Breakfast Menu Section with Add Functionality -->
          <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
          <v-flex xs12 sm10 pt-4 align-self-center>
            <v-layout wrap justify-start>
              <v-flex xs10 align-self-center>
                <v-text-field
              dense
              v-model="newBreakfastMenuItem"
              outlined
              type="text"
              hide-details="auto"
            />
              </v-flex>
              <v-flex xs2 align-self-center>
            <v-icon @click="addBreakfastMenuItem" color="orange">mdi-plus</v-icon>

              </v-flex>
            </v-layout>
           
          </v-flex>

          <!-- Display Breakfast Menu Items -->
          <v-flex xs12 sm10 pt-4 text-left v-if="breakfastMenu.length > 0">
            <v-layout wrap>
              <v-flex xs12 v-for="(item, index) in breakfastMenu" :key="index" class="pt-4">
                <v-layout wrap justify-start>
                  <v-flex xs10 align-self-center>
                    <v-text-field
                      dense
                      v-model="breakfastMenu[index]"
                      outlined hide-details="auto"
                      type="text"
                      hide-spin-buttons
                      disabled
                    />
                  </v-flex>
                  <v-flex xs2 align-self-center>
                    <v-icon @click="deleteBreakfastMenuItem(index)">mdi-delete</v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- Lunch Section -->
      <v-flex xs12 sm6 md3 pr-0 pr-md-2 pt-4>
        <v-layout wrap justify-start>
          <v-flex xs6 align-self-center>
            <span class="title2">Lunch</span>
          </v-flex>
          <v-flex xs4 align-self-center>
            <v-text-field
              dense
              v-model="lunchRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            />
          </v-flex>

          <!-- Lunch Menu Section with Add Functionality -->
          <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
          <v-flex xs12 sm10 pt-4 align-self-center>
            <v-layout wrap justify-start>
              <v-flex xs10 align-self-center>
            <v-text-field
              dense
              v-model="newLunchMenuItem"
              outlined
              type="text"
              hide-details="auto"
            />  </v-flex>
            <v-flex xs2 align-self-center>
            <v-icon @click="addLunchMenuItem" color="orange">mdi-plus</v-icon>
          </v-flex>
        </v-layout>
          </v-flex>

          <!-- Display Lunch Menu Items -->
          <v-flex xs12 sm10 pt-4 text-left v-if="lunchMenu.length > 0">
            <v-layout wrap>
              <v-flex xs12 v-for="(item, index) in lunchMenu" :key="index" class="pt-4">
                <v-layout wrap justify-start>
                  <v-flex xs10 align-self-center>
                    <v-text-field
                      dense
                      v-model="lunchMenu[index]"
                      outlined
                      type="text" hide-details
                      hide-spin-buttons
                      disabled
                    />
                  </v-flex>
                  <v-flex xs2 align-self-center>
                    <v-icon @click="deleteLunchMenuItem(index)">mdi-delete</v-icon>
                  </v-flex>
                <!-- </v-layout>
                  </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- Snacks Section -->
      <v-flex xs12 sm6 md3 pr-0 pr-md-2 pt-4>
        <v-layout wrap justify-start>
          <v-flex xs6 align-self-center>
            <span class="title2">Tea/Coffee-Snacks</span>
          </v-flex>
          <v-flex xs4 align-self-center>
            <v-text-field
              dense
              v-model="snacksRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            />
          </v-flex>

          <!-- Snacks Menu Section with Add Functionality -->
          <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
          <v-flex xs12 sm10 pt-4 align-self-center>
            <v-layout wrap justify-start>
              <v-flex xs10 align-self-center>
            <v-text-field
              dense
              v-model="newSnacksMenuItem"
              outlined
              type="text"
              hide-details="auto"
            />  </v-flex>
            <v-flex xs2 align-self-center>
            <v-icon @click="addSnacksMenuItem" color="orange">mdi-plus</v-icon>
          </v-flex>
        </v-layout>
          </v-flex>

          <!-- Display Snacks Menu Items -->
          <v-flex xs12 sm10 pt-4 text-left v-if="snacksMenu.length > 0">
            <v-layout wrap>
              <v-flex xs12 v-for="(item, index) in snacksMenu" :key="index" class="pt-4">
                <v-layout wrap justify-start>
                  <v-flex xs10 align-self-center>
                    <v-text-field
                      dense
                      v-model="snacksMenu[index]"
                      outlined hide-details
                      type="text"
                      hide-spin-buttons
                      disabled
                    />
                  </v-flex>
                  <v-flex xs2 align-self-center>
                    <v-icon @click="deleteSnacksMenuItem(index)">mdi-delete</v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- Dinner Section -->
      <v-flex xs12 sm6 md3 pr-0 pr-md-2 pt-4>
        <v-layout wrap justify-start>
          <v-flex xs6 align-self-center>
            <span class="title2">Dinner</span>
          </v-flex>
          <v-flex xs4 align-self-center>
            <v-text-field
              dense
              v-model="dinnerRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            />
          </v-flex>

          <!-- Dinner Menu Section with Add Functionality -->
          <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
          <v-flex xs12 sm10 pt-4 align-self-center>
            <v-layout wrap justify-start>
              <v-flex xs10 align-self-center>
            <v-text-field
              dense
              v-model="newDinnerMenuItem"
              outlined
              type="text" 
              hide-details="auto"
            />  </v-flex>
            <v-flex xs2 align-self-center>
            <v-icon @click="addDinnerMenuItem" color="orange">mdi-plus</v-icon>
          </v-flex>
        </v-layout>
          </v-flex>

          <!-- Display Dinner Menu Items -->
          <v-flex xs12 sm10 pt-4 text-left v-if="dinnerMenu.length > 0">
            <v-layout wrap>
              <v-flex xs12 v-for="(item, index) in dinnerMenu" :key="index" class="pt-4">
                <v-layout wrap justify-start>
                  <v-flex xs10 align-self-center>
                    <v-text-field
                      dense
                      v-model="dinnerMenu[index]"
                      outlined
                      type="text" hide-details
                      hide-spin-buttons
                      disabled
                    />
                  </v-flex>
                  <v-flex xs2 align-self-center>
                    <v-icon @click="deleteDinnerMenuItem(index)">mdi-delete</v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start>
      <v-flex xs12 align-self-center py-2>
       <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 align-self-center>
        <span class="title222">Create menu</span>
      </v-flex>
      <v-flex xs12 align-self-center>
        <v-layout wrap>
      <!-- Food Name -->
      <v-flex xs12 sm4 px-1 align-self-center>
        <v-text-field
          dense
          v-model="newFood.name"
          outlined
          label="Food Name"
          hide-details="auto"
        />
      </v-flex>

      <!-- Quantity/Portion -->
      <v-flex xs12 sm2 px-1 align-self-center>
        <v-text-field
          dense
          v-model="newFood.quantity"
          outlined
          label="Quantity/Portion"
          hide-details="auto"
        />
      </v-flex>

      <!-- Rate/Head -->
      <v-flex xs12 sm2 px-1 align-self-center>
        <v-text-field
          dense
          v-model="newFood.rate"
          outlined
          label="Rate/Head"
          type="number"
          hide-spin-buttons
          hide-details="auto"
        />
      </v-flex>

      <!-- Food Availability Switch -->
      <v-flex xs12 sm2 px-1 align-self-center>
        <v-switch
          v-model="newFood.availability"
          label="Available" class="mt-0"
          hide-details="auto"  color="orange"
        />
      </v-flex>

      <!-- Plus Icon to Add the Food Object -->
      <v-flex xs12 sm1 align-self-center>
        <v-icon
    @click="addFood"
    color="orange"
    :disabled="!newFood.name || !newFood.quantity || !newFood.rate"
  >mdi-plus</v-icon>
      </v-flex>
    </v-layout>
<v-layout wrap justify-start>
    <!-- Display the Added Food Items -->
    <v-flex xs12  pt-4 text-left v-if="foodList.length > 0">
      <v-layout wrap justify-start>
        <v-flex xs12 v-for="(food, index) in foodList" :key="index" class="pt-4">
          <v-layout wrap justify-start>
            <!-- Display Food Name -->
            <v-flex xs12 sm4 px-1 align-self-center>
              <v-text-field
                dense
                v-model="food.name"
                outlined hide-details
                label="Food Name"
                hide-spin-buttons
                disabled
              />
            </v-flex>

            <!-- Display Quantity -->
            <v-flex xs12 sm2 px-1 align-self-center>
              <v-text-field
                dense
                v-model="food.quantity"
                outlined hide-details
                label="Quantity"
                hide-spin-buttons
                disabled
              />
            </v-flex>

            <!-- Display Rate/Head -->
            <v-flex xs12 sm2 px-1 align-self-center>
              <v-text-field
                dense
                v-model="food.rate"
                outlined hide-details
                label="Rate/Head"
                hide-spin-buttons
                disabled
              />
            </v-flex>

            <!-- Display Availability (Switch) -->
            <v-flex xs12 sm2 px-1 align-self-center>
              <v-switch
                v-model="food.availability"
                label="Available" class="mt-0"
                hide-details="auto"
                disabled  color="orange"
              />
            </v-flex>

            <!-- Delete Icon to Remove the Food Object -->
            <v-flex xs1 align-self-center text-left>
              <v-icon @click="deleteFood(index)" color="red">mdi-delete</v-icon>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      </v-flex>
    </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-end>
    <v-flex xs12 sm6 md3 pt-4 pr-0 pr-sm-2 pr-md-10>

 
  <v-btn
                  @click="AddRoomFood()"
                  block
                  class="btnstly"
                  style="cursor: pointer"
                  ><span style="font-family: LexendFont; text-transform: none"
                    >Save</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["roomData"],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      page: 1,
      limit: 20,
      RoomId: localStorage.getItem("RoomId"),
      gstNumber: "",
      gstAvailable: true,
      isCake: false,
      cakeRate: 0,
      isFruitBasket: false,
      fruitBasketRate: 0,
      isBBQ: false,
      bbqRate: 0,
      isCook: false,
      cookRate: 0,
      isHoneymoon: false,
      honeymoonRate: 0,
      breakfastRate: 0,
      lunchRate: 0,
      snacksRate: 0,
      dinnerRate: 0,
      // breakfastMenu: "",
      // lunchMenu: "",
      // snacksMenu: "",
      // dinnerMenu: "",
   // New menu items for each section
   newBreakfastMenuItem: '',
      newLunchMenuItem: '',
      newSnacksMenuItem: '',
      newDinnerMenuItem: '',

      // Menu arrays for each section
      breakfastMenu: [],
      lunchMenu: [],
      snacksMenu: [],
      dinnerMenu: [],
      // breakfastArray: [],
      // newItemName: "",
      // newRate: "",
      newFood: {
        name: '',
        quantity: '',
        rate: '',
        availability: false,
      },
      foodList: [],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      pinRules: [
        (v) => !!v || "PIN is required",
        (v) => (v && v.length === 6) || "PIN must be 6 digits",
      ],
      phoneRules: [
        (v) => !v || v.length <= 10 || "number must be less than 11 characters",
        (v) =>
          !v || v.length >= 10 || "number must be greater than 10 characters",
        (v) => !v || /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },
  //   mounted() {

  //  this.getData();
  //   },

  methods: {
    addFood() {
  // Check if all fields are filled
  if (this.newFood.name && this.newFood.quantity && this.newFood.rate) {
    // Add the current food object to the foodList array
    this.foodList.push({ ...this.newFood });

    // Clear the current input fields
    this.newFood = {
      name: '',
      quantity: '',
      rate: '',
      availability: false,
    };
  } else {
    // Optionally, you can alert the user that all fields must be filled
    console.warn('All fields must be filled!');
  }
},
    deleteFood(index) {
      // Remove the food item at the given index
      this.foodList.splice(index, 1);
    },
    AddRoomFood() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/room/add/foodandbeverage",
        data: {
          gstAvailable: this.gstAvailable,
          gstNumber: this.gstNumber,
          isCake: this.isCake,
          cakeRate: this.cakeRate,
          isFruitBasket: this.isFruitBasket,
          fruitBasketRate: this.fruitBasketRate,
          isBBQ: this.isBBQ,
          bbqRate: this.bbqRate,
          isCook: this.isCook,
          cookRate: this.cookRate,
          isHoneymoon: this.isHoneymoon,
          honeymoonRate: this.honeymoonRate,
          breakfastRate: this.breakfastRate,
          lunchRate: this.lunchRate,
          snacksRate: this.snacksRate,
          dinnerRate: this.dinnerRate,
          breakfastMenu: this.breakfastMenu,
          lunchMenu: this.lunchMenu,
          snacksMenu: this.snacksMenu,
          dinnerMenu: this.dinnerMenu,
          roomId: localStorage.getItem("RoomId"),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
           localStorage.removeItem("RoomId");
           this.$router.push("/hotel");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    // addBreakfastItem() {
    //   if (this.newItemName && this.newRate) {
    //     this.breakfastArray.push({
    //       itemName: this.newItemName,
    //       rate: this.newRate,
    //     });
    //     this.newItemName = "";
    //     this.newRate = "";
    //   }
    // },
    // deleteBreakfastItem(index) {
    //   this.breakfastArray.splice(index, 1);
    // },
    addBreakfastMenuItem() {
      if (this.newBreakfastMenuItem) {
        this.breakfastMenu.push(this.newBreakfastMenuItem);
        this.newBreakfastMenuItem = ''; // Reset field
      }
    },
    deleteBreakfastMenuItem(index) {
      this.breakfastMenu.splice(index, 1); // Remove menu item
    },

    // Similar methods for Lunch, Snacks, Dinner

    addLunchMenuItem() {
      if (this.newLunchMenuItem) {
        this.lunchMenu.push(this.newLunchMenuItem);
        this.newLunchMenuItem = '';
      }
    },
    deleteLunchMenuItem(index) {
      this.lunchMenu.splice(index, 1);
    },

    addSnacksMenuItem() {
      if (this.newSnacksMenuItem) {
        this.snacksMenu.push(this.newSnacksMenuItem);
        this.newSnacksMenuItem = '';
      }
    },
    deleteSnacksMenuItem(index) {
      this.snacksMenu.splice(index, 1);
    },

    addDinnerMenuItem() {
      if (this.newDinnerMenuItem) {
        this.dinnerMenu.push(this.newDinnerMenuItem);
        this.newDinnerMenuItem = '';
      }
    },
    deleteDinnerMenuItem(index) {
      this.dinnerMenu.splice(index, 1);
    }
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>